import Default from '../../Default/Theme';

const fixedWidths = {
  '@sm': {
    maxWidth: 'smContainer'
  },
  '@md': {
    maxWidth: 'mdContainer'
  },
  '@lg': {
    maxWidth: 'lgContainer'
  }
};
const fullBleedStyle = {
  '> div': {
    paddingHorizontal: 'medium'
  }
};

class FixedWidth extends Default {
  Widget(props) {
    const { widgetType, widgetPreset } = this.base;
    const fixedWidthStyle =
      !['HEADER', 'FOOTER'].includes(widgetType) && widgetPreset !== 'introduction1'
        ? {
          'position': 'relative',
          '> div': {
            margin: 'auto',
            ...fixedWidths
          }
        }
        : {};
    const style = {
      backgroundColor: 'section',
      ...fixedWidthStyle
    };

    return super.Widget(this.merge({ style }, props));
  }

  WidgetBanner(props) {
    return this.Widget(this.merge({ style: fullBleedStyle, groupType: 'Banner' }, props));
  }

  WidgetSplit(props) {
    return this.Widget(this.merge({ style: fullBleedStyle, groupType: 'Split' }, props));
  }

  MapBanner(props) {
    return super.MapBanner(
      this.merge(
        {
          style: {
            marginHorizontal: 'medium'
          }
        },
        props
      )
    );
  }

  SplitItem(props) {
    return super.SplitItem(
      this.merge(
        {
          style: {
            '@md': {
              // Allow split content to take up the entire container
              '> *': {
                maxWidth: '100%'
              },
              ':first-child': {
                paddingRight: 'medium'
              },
              ':last-child': {
                paddingLeft: 'medium'
              },
              ':only-child': {
                paddingHorizontal: 0
              }
            }
          }
        },
        props
      )
    );
  }

  SplitItemImage(props) {
    return super.SplitItemImage(
      this.merge(
        {
          style: {
            '@md': {
              ':first-child': {
                paddingRight: 0
              },
              ':last-child': {
                paddingLeft: 0
              }
            }
          }
        },
        props
      )
    );
  }

  SectionSplitContainer(props) {
    return super.Container(
      this.merge(
        {
          style: {
            paddingHorizontal: 0
          }
        },
        props
      )
    );
  }
}

export default FixedWidth;
